'use client';

import React, { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import NextError from 'next/error';
import RootLayout from '../layout/root-layout';
import '../common.css';

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}): React.JSX.Element {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <RootLayout>
      {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any -- It's crunch */}
      <NextError statusCode={undefined as any} />
    </RootLayout>
  );
}
