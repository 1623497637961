import { commonEnv } from './env';

export const rootUrl = commonEnv.NEXT_PUBLIC_ROOT_URL;
export const cdnUrl = commonEnv.NEXT_PUBLIC_PAGES_URL;
export const pagesAppUrl = commonEnv.NEXT_PUBLIC_PAGES_URL;
export const cabinetAppUrl = commonEnv.NEXT_PUBLIC_CABINET_URL;
export const authAppUrl = commonEnv.NEXT_PUBLIC_AUTH_URL;
export const aboutAppUrl = commonEnv.NEXT_PUBLIC_ABOUT_URL;
export const termsPageUrl = `${aboutAppUrl}/terms-and-privacy`;
export const createProjectPageUrl = `${cabinetAppUrl}/p/create`;
export const aboutPageUrl = `${aboutAppUrl}/about`;
export const supportUsPageUrl = `${aboutAppUrl}/support-us`;
export const contactsPageUrl = `${aboutAppUrl}/contacts`;
export const googleAnalyticsId = commonEnv.NEXT_PUBLIC_GA_ID;
export const isProduction = process.env.NODE_ENV === 'production';
export const releaseVersion = commonEnv.NEXT_PUBLIC_RELEASE_VERSION;
