import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const commonEnv = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    NODE_ENV: z
      .enum(['development', 'production', 'test'])
      .default('development'),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_RELEASE_VERSION: z.string().min(1),
    NEXT_PUBLIC_GA_ID: z.string().min(1),
    NEXT_PUBLIC_ROOT_URL: z.string().url(),
    NEXT_PUBLIC_PAGES_URL: z.string().url(),
    NEXT_PUBLIC_CABINET_URL: z.string().url(),
    NEXT_PUBLIC_AUTH_URL: z.string().url(),
    NEXT_PUBLIC_ABOUT_URL: z.string().url(),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_RELEASE_VERSION: process.env.NEXT_PUBLIC_RELEASE_VERSION,
    NEXT_PUBLIC_GA_ID: process.env.NEXT_PUBLIC_GA_ID,
    NEXT_PUBLIC_ROOT_URL: process.env.NEXT_PUBLIC_ROOT_URL,
    NEXT_PUBLIC_PAGES_URL: process.env.NEXT_PUBLIC_PAGES_URL,
    NEXT_PUBLIC_CABINET_URL: process.env.NEXT_PUBLIC_CABINET_URL,
    NEXT_PUBLIC_AUTH_URL: process.env.NEXT_PUBLIC_AUTH_URL,
    NEXT_PUBLIC_ABOUT_URL: process.env.NEXT_PUBLIC_ABOUT_URL,
  },
});
