import { z } from 'zod';

export const responseSchema = z
  .object({
    url: z.string().url(),
    title: z.string(),
    label: z.string(),
    stopAt: z.coerce.date().nullable(),
  })
  .passthrough();
