'use strict';

/**
 * This is the Sentry DSN for the Node.js Website Project
 */
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

/**
 * This is the Sentry Auth Token for the Node.js Website Project
 */
export const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN;

/**
 * This states if Sentry should be enabled and bundled within our App
 *
 * We enable sentry by default if we're on development mode
 */
export const SENTRY_ENABLE = Boolean(SENTRY_DSN);

/**
 * This configures the sampling rate for Sentry
 *
 * We always want to capture 100% on Development Mode
 * and not when it's on Production Mode
 */
export const SENTRY_CAPTURE_RATE = process.env.NEXT_PUBLIC_SENTRY_CAPTURE_RATE ?
  parseFloat(process.env.NEXT_PUBLIC_SENTRY_CAPTURE_RATE) : 1.0;

export const SENTRY_ALLOWED_URLS = [
  /^https?:\/\/(?:.+\.)?zbir\.net/,
  /^https?:\/\/(?:.+\.)?nip\.io/
];

/**
 * Provides the Route for Sentry's Server-Side Tunnel
 *
 * This is a `@sentry/nextjs` specific feature
 */
export const SENTRY_TUNNEL_ROUTE = () =>
  SENTRY_ENABLE ? '/monitoring' : undefined;
export const SENTRY_TUNNEL_URL = (components = '') =>
  SENTRY_ENABLE ? `${process.env.NEXT_PUBLIC_SENTRY_TUNNEL_HOST || ''}/monitoring${components}` : undefined;

/**
 * This configures which Sentry features to tree-shake/remove from the Sentry bundle
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/tree-shaking/
 */
export const SENTRY_EXTENSIONS = {
  __SENTRY_DEBUG__: false,
  __SENTRY_TRACING__: false,
  __RRWEB_EXCLUDE_IFRAME__: true,
  __RRWEB_EXCLUDE_SHADOW_DOM__: true,
  __SENTRY_EXCLUDE_REPLAY_WORKER__: true,
};
