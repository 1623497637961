import Script from 'next/script';
import type { Metadata } from 'next';
import React, { Suspense } from 'react';
import {
  cdnUrl,
  googleAnalyticsId,
  isProduction,
} from '@repo/common/constants';
import { projectName } from '@repo/metadata';

const metaThemeColor = '#587549';
const metaAssetsVersion = '1.1';

export const metadata: Metadata = {
  applicationName: projectName,
  manifest: `${cdnUrl}/favicon/site.webmanifest?v=${metaAssetsVersion}`,
  icons: {
    apple: [
      {
        url: `${cdnUrl}/favicon/apple-touch-icon.png?v=${metaAssetsVersion}`,
        sizes: '180x180',
        type: 'image/png',
      },
    ],
    icon: [
      {
        url: `${cdnUrl}/favicon/favicon-32x32.png?v=${metaAssetsVersion}`,
        sizes: '32x32',
        type: 'image/png',
      },
      {
        url: `${cdnUrl}/favicon/favicon-16x16.png?v=${metaAssetsVersion}`,
        sizes: '16x16',
        type: 'image/png',
      },
    ],
    shortcut: [`${cdnUrl}/favicon/favicon.ico?v=${metaAssetsVersion}`],
    other: [
      {
        rel: 'mask-icon',
        url: `${cdnUrl}/favicon/safari-pinned-tab.svg?v=${metaAssetsVersion}`,
        color: metaThemeColor,
      },
    ],
  },
  appleWebApp: {
    title: projectName,
  },
  other: {
    'msapplication-TileColor': metaThemeColor,
    'msapplication-config': `${cdnUrl}/favicon/browserconfig.xml?v=${metaAssetsVersion}`,
    'theme-color': metaThemeColor,
  },
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element {
  return (
    <html lang='ukr'>
      <body>
        <Suspense>{children}</Suspense>

        {isProduction ? (
          <>
            <Script
              src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
              strategy='afterInteractive'
            />
            <Script id='google-analytics' strategy='afterInteractive'>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${googleAnalyticsId}');
            `}
            </Script>
          </>
        ) : null}
      </body>
    </html>
  );
}
