'use client';

import Link from 'next/link';
import React from 'react';
import useSWR from 'swr';
import { responseSchema } from './schema';

interface AnnouncementBarProps {
  url: string;
  title: string;
  label: string;
  stopAt?: string;
}

const announcementApiUrl = 'https://announcement-api.zbir.net/index.json';
const swrFetcher = (url: string): Promise<AnnouncementBarProps> =>
  fetch(url, { cache: 'no-cache' }).then(
    (res): Promise<AnnouncementBarProps> => res.json(),
  );

export default function AnnouncementBar(): React.JSX.Element | null {
  const { data } = useSWR(announcementApiUrl, swrFetcher);

  if (!data) {
    return null;
  }

  try {
    const content = responseSchema.parse(data);

    if (content.stopAt) {
      const now = new Date();
      if (content.stopAt.getTime() < now.getTime()) {
        return null;
      }
    }

    return (
      <Link
        className='p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full hidden lg:inline-flex'
        href={content.url}
        role='alert'
        target='_blank'>
        <span className='flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3'>
          {content.label}
        </span>
        <span className='font-semibold mr-2 text-left flex-auto'>
          {content.title}
        </span>
        <svg
          className='fill-current opacity-75 h-4 w-4'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'>
          <path d='M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z' />
        </svg>
      </Link>
    );
  } catch (error) {
    return null;
  }
}
