export const projectName = 'ЗБІР';
export const projectDescription =
  'Створюйте зручні сторінки для просування зборів на потреби ЗСУ з автоматичним оновленням прогресу збору.';

export function buildPageTitle(title: string, appName?: string): string {
  return `${title} · ${projectName}${appName ? `.${appName}` : ''}`;
}

export function buildAppName(name: string): string {
  return `${projectName}.${name}`;
}
